import { Image } from "noyirmibir-react";
import { Link } from "react-router-dom";
import { Urls } from "../../navigation/main";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "./header.scss"
import { useState } from "react";
import { useStore } from "../../store/store";
import { MenuModel, MenuModelItem } from "../../models/global";

const Header = () => {
    const menus = useStore(state => state.menus);

    const headerLeftMenu = menus.find((item: MenuModel) => {
        return item.name == "Header Left";
    });
    const headerRightMenu = menus.find((item: MenuModel) => {
        return item.name == "Header Right";
    });

    const [showMenu, setShowMenu] = useState<boolean>(false);

    return (
        <section className="header">
            <div className="container">
                <div className="mobile-icon mobile-only" onClick={() => {
                    setShowMenu(true)
                }}>
                    <span></span>
                </div>
                <div className={"mobile-menu mobile-only" + (showMenu ? " active": "")}>
                    <div className="items">
                        {headerLeftMenu && headerLeftMenu.items.map((item: MenuModelItem, index: number) => {
                            if(item.link.startsWith('https') || item.link.startsWith('http') || item.link.startsWith('mailto') || item.link.startsWith('tel')) {
                                return (
                                    <a key={"mobile-header-left-" + index} href={item.link} target="_blank" className="item">
                                        <AnimationOnScroll animateIn="animate__fadeIn" delay={index * 100} offset={0}>
                                            <span>
                                                {item.name}
                                            </span>
                                            {item.image &&
                                                <Image desktop={item.image}></Image>
                                            }
                                        </AnimationOnScroll>
                                    </a>
                                )
                            }
                            else{
                                return (
                                    <Link key={"mobile-header-left-" + index} to={item.link} className="item" onClick={() => {
                                        setShowMenu(false)
                                    }}>
                                        <AnimationOnScroll animateIn="animate__fadeIn" delay={index * 100} offset={0}>
                                            <span>
                                                {item.name}
                                            </span>
                                            {item.image &&
                                                <Image desktop={item.image}></Image>
                                            }
                                        </AnimationOnScroll>
                                    </Link>
                                )
                            }
                        })}
                        {headerRightMenu && headerRightMenu.items.map((item: MenuModelItem, index: number) => {
                            if(item.link.startsWith('https') || item.link.startsWith('http') || item.link.startsWith('mailto') || item.link.startsWith('tel')) {
                                return (
                                    <a key={"mobile-header-right-" + index} href={item.link} target="_blank" className="item">
                                        <AnimationOnScroll animateIn="animate__fadeIn" delay={((headerLeftMenu?.items??[]).length + index) * 100} offset={0}>
                                            <span>
                                                {item.name}
                                            </span>
                                            {item.image &&
                                                <Image desktop={item.image}></Image>
                                            }
                                        </AnimationOnScroll>
                                    </a>
                                )
                            }
                            else{
                                return (
                                    <Link key={"mobile-header-right-" + index} to={item.link} className="item" onClick={() => {
                                        setShowMenu(false)
                                    }}>
                                        <AnimationOnScroll animateIn="animate__fadeIn" delay={((headerLeftMenu?.items??[]).length + index) * 100} offset={0}>
                                            <span>
                                                {item.name}
                                            </span>
                                            {item.image &&
                                                <Image desktop={item.image}></Image>
                                            }
                                        </AnimationOnScroll>
                                    </Link>
                                )
                            }
                        })}
                        <a href="https://www.instagram.com/ozgunlukokulu/" target={"_blank"} className="item">
                            <AnimationOnScroll animateIn="animate__fadeIn" delay={500} offset={0}>
                                <Image desktop={require("../../assets/icons/instagram.svg").default}></Image>
                            </AnimationOnScroll>
                        </a>
                    </div>
                    <div className="close" onClick={() => {
                        setShowMenu(false)
                    }}>
                        <AnimationOnScroll animateIn="animate__fadeIn" delay={500} offset={0}>
                            <Image desktop={require("../../assets/icons/close-color.svg").default}></Image>
                        </AnimationOnScroll>
                    </div>
                </div>
                <div className="column left">
                    <div className="items">
                        {headerLeftMenu && headerLeftMenu.items.map((item: MenuModelItem, index: number) => {
                            if(item.link.startsWith('https') || item.link.startsWith('http') || item.link.startsWith('mailto') || item.link.startsWith('tel')) {
                                return (
                                    <a key={"mobile-header-left-" + index} href={item.link} target="_blank" className="item">
                                        <AnimationOnScroll animateIn="animate__fadeIn" delay={index * 100} offset={0}>
                                            <span>
                                                {item.name}
                                            </span>
                                            {item.image &&
                                                <Image desktop={item.image}></Image>
                                            }
                                        </AnimationOnScroll>
                                    </a>
                                )
                            }
                            else{
                                return (
                                    <Link key={"mobile-header-left-" + index} to={item.link} className="item">
                                        <AnimationOnScroll animateIn="animate__fadeIn" delay={index * 100} offset={0}>
                                            <span>
                                                {item.name}
                                            </span>
                                            {item.image &&
                                                <Image desktop={item.image}></Image>
                                            }
                                        </AnimationOnScroll>
                                    </Link>
                                )
                            }
                        })}
                    </div>
                </div>
                <div className="logo">
                    <AnimationOnScroll animateIn="animate__fadeIn" offset={0}>
                        <Link to={Urls.Home}>
                            <Image desktop={require("../../assets/icons/logo.svg").default}></Image>
                        </Link>
                    </AnimationOnScroll>
                </div>
                <div className="column right">
                    <div className="items">
                        {headerRightMenu && headerRightMenu.items.map((item: MenuModelItem, index: number) => {
                            if(item.link.startsWith('https') || item.link.startsWith('http') || item.link.startsWith('mailto') || item.link.startsWith('tel')) {
                                return (
                                    <a key={"mobile-header-right-" + index} href={item.link} target="_blank" className="item">
                                        <AnimationOnScroll animateIn="animate__fadeIn" delay={((headerLeftMenu?.items??[]).length + index) * 100} offset={0}>
                                            <span>
                                                {item.name}
                                            </span>
                                            {item.image &&
                                                <Image desktop={item.image}></Image>
                                            }
                                        </AnimationOnScroll>
                                    </a>
                                )
                            }
                            else{
                                return (
                                    <Link key={"mobile-header-right-" + index} to={item.link} className="item">
                                        <AnimationOnScroll animateIn="animate__fadeIn" delay={((headerLeftMenu?.items??[]).length + index) * 100} offset={0}>
                                            <span>
                                                {item.name}
                                            </span>
                                            {item.image &&
                                                <Image desktop={item.image}></Image>
                                            }
                                        </AnimationOnScroll>
                                    </Link>
                                )
                            }
                        })}
                        <a href="https://www.instagram.com/ozgunlukokulu/" target={"_blank"} className="item">
                            <AnimationOnScroll animateIn="animate__fadeIn" delay={500} offset={0}>
                                <Image desktop={require("../../assets/icons/instagram.svg").default}></Image>
                            </AnimationOnScroll>
                        </a>
                    </div>
                </div>
            </div>  
        </section>
    )
}

export default Header;