import { AnouncementModel, HighlightModel, MenuModel, ProgramModel, StaticPageModel, StringResourceModel } from "../models/global";
import { useStore } from "../store/store";
import { call } from "./api";

export const getStringResources = async () => {
    await call({
        url: 'v1/getStringResources',
        method: 'get',
        preventLoading: true,
        successFunc: async (response: StringResourceModel) => {
            const setStringResources = useStore.getState().setStringResources;
            await setStringResources(response);
        }
    });
}

export const getMenus = async () => {
    await call({
        url: 'v1/getMenus',
        method: 'get',
        preventLoading: true,
        successFunc: async (response: MenuModel[]) => {
            const setMenus = useStore.getState().setMenus;
            await setMenus(response);
        }
    });
}

export const getPrograms = async () => {
    await call({
        url: 'v1/getPrograms',
        method: 'get',
        preventLoading: true,
        successFunc: async (response: ProgramModel[]) => {
            const setPrograms = useStore.getState().setPrograms;
            await setPrograms(response);
        }
    });
}

export const getHighlights = async () => {
    await call({
        url: 'v1/getHighlights',
        method: 'get',
        preventLoading: true,
        successFunc: async (response: HighlightModel[]) => {
            const setHighlights = useStore.getState().setHighlights;
            await setHighlights(response);
        }
    });
}

export const getAnouncements = async () => {
    await call({
        url: 'v1/getAnouncements',
        method: 'get',
        preventLoading: true,
        successFunc: async (response: AnouncementModel[]) => {
            const setAnouncements = useStore.getState().setAnouncements;
            await setAnouncements(response);
        }
    });
}

export const getStaticPages = async () => {
    await call({
        url: 'v1/getStaticPages',
        method: 'get',
        preventLoading: true,
        successFunc: async (response: StaticPageModel[]) => {
            const setStaticPages = useStore.getState().setStaticPages;
            await setStaticPages(response);
        }
    });
}