import { Image } from "noyirmibir-react";
import { Swiper as SwiperType, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import parse from 'html-react-parser';
import { htmlParseOptions, isExternalLink } from "../../actions/utils";
import "./slider-list.scss"
import 'swiper/css';
import 'swiper/css/navigation';
import { useRef } from "react";
import { Link } from "react-router-dom";
import { AnimationOnScroll } from 'react-animation-on-scroll';

type SliderListProps = {
    title?: string
    linkUrl?: string
    linkText?: string
    items: SliderListPropsItem[]
    backgroundColor?: string
}

type SliderListPropsItem = {
    image: string
    title?: string
    text?: string
    linkUrl?: string
}

const SliderList = (props: SliderListProps) => {
    const swiperRef = useRef<SwiperType|null>(null);

    return (
        <section className="slider-list" style={{ backgroundColor: (props.backgroundColor??"") }}>
            <div className="container">
                <div className="top">
                    {props.title &&
                        <AnimationOnScroll animateIn="animate__fadeIn" offset={0}>
                            <div className="title">
                                {parse(props.title, htmlParseOptions)}
                            </div>
                        </AnimationOnScroll>
                    }
                    {props.linkText &&
                        <AnimationOnScroll animateIn="animate__fadeIn" offset={0}>
                            {props.linkUrl && isExternalLink(props.linkUrl) ?
                                <a href={props.linkUrl} className="link" target={"_blank"}>
                                    <span>
                                        {parse(props.linkText, htmlParseOptions)}
                                    </span>
                                    <Image desktop={require("../../assets/icons/arrow.svg").default}></Image>
                                </a>
                                :
                                <Link to={(props.linkUrl ? props.linkUrl : "#")} className="link">
                                    <span>
                                        {parse(props.linkText, htmlParseOptions)}
                                    </span>
                                    <Image desktop={require("../../assets/icons/arrow.svg").default}></Image>
                                </Link>
                            }
                        </AnimationOnScroll>
                    }
                </div>
                <div className="items">
                    <Swiper
                        modules={[Navigation]}
                        loop={true}
                        slidesPerView={2}
                        breakpoints={{
                            0: {
                                slidesPerView: 1.12,
                                centeredSlides: true
                            },
                            901: {
                              slidesPerView: 2
                            }
                        }}
                        onBeforeInit={(swiper: any) => {
                            swiperRef.current = swiper;
                        }}
                    >
                        {props.items.map((item: SliderListPropsItem, index: number) => {
                            return (
                                <SwiperSlide key={"slider-list-" + index}>
                                    <AnimationOnScroll animateIn="animate__fadeIn" delay={index * 100} offset={0}>
                                        {item.linkUrl && isExternalLink(item.linkUrl) ?
                                            <a className="item" href={item.linkUrl} target={"_blank"}>
                                                <Image classNames="image" desktop={item.image} />
                                                {(!!item.title || !!item.text) &&
                                                    <div className="content">
                                                        {!!item.title &&
                                                            <div className="title">
                                                                {parse(item.title, htmlParseOptions)}
                                                            </div>
                                                        }
                                                        {!!item.text &&
                                                            <div className="text">
                                                                {parse(item.text, htmlParseOptions)}
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </a>
                                            :
                                            <Link className="item" to={(item.linkUrl ? item.linkUrl : "#")}>
                                                <Image classNames="image" desktop={item.image} />
                                                {(!!item.title || !!item.text) &&
                                                    <div className="content">
                                                        {!!item.title &&
                                                            <div className="title">
                                                                {parse(item.title, htmlParseOptions)}
                                                            </div>
                                                        }
                                                        {!!item.text &&
                                                            <div className="text">
                                                                {parse(item.text, htmlParseOptions)}
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </Link>
                                        }
                                    </AnimationOnScroll>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                    {props.items.length > 2 &&
                        <>
                            <div className="navigation left" onClick={() => swiperRef.current?.slidePrev()}>
                                <Image desktop={require("../../assets/icons/arrow-left.svg").default}></Image>
                            </div>
                            <div className="navigation right" onClick={() => swiperRef.current?.slideNext()}>
                                <Image desktop={require("../../assets/icons/arrow-right.svg").default}></Image>
                            </div>
                        </>
                    }
                </div>
            </div>  
        </section>
    )
}

export default SliderList;