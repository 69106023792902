import { Image } from "noyirmibir-react";
import { Link } from "react-router-dom";
import parse from 'html-react-parser';
import { htmlParseOptions } from "../../actions/utils";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "./item-preview.scss"

type ItemPreviewProps = {
    title?: string
    text?: string
    items: ItemPreviewPropsItem[],
    linkText?: string
    linkUrl?: string
}

type ItemPreviewPropsItem = {
    image: string
    title: string
}

const ItemPreview = (props: ItemPreviewProps) => {
    return (
        <section className="item-preview">
            <div className="container">
                {props.title &&
                    <AnimationOnScroll animateIn="animate__fadeIn" offset={0}>
                        <div className="title">
                            {parse(props.title, htmlParseOptions)}
                        </div>
                    </AnimationOnScroll>
                }
                {props.text &&
                    <AnimationOnScroll animateIn="animate__fadeIn" offset={0}>
                        <div className="text">
                            {parse(props.text, htmlParseOptions)}
                        </div>
                    </AnimationOnScroll>
                }
                <div className="items">
                    {props.items.map((item: ItemPreviewPropsItem, index: number) => {
                        return (
                            <div className="item" key={"item-preview-" + index}>
                                <AnimationOnScroll animateIn="animate__fadeIn" delay={index * 100} offset={0}>
                                    <div className="image">
                                        <Image desktop={item.image}></Image>
                                    </div>
                                    {item.title &&
                                        <div className="title">
                                            {parse(item.title, htmlParseOptions)}
                                        </div>
                                    }
                                </AnimationOnScroll>
                            </div>   
                        )
                    })}
                </div>
                {props.linkText &&
                    <div className="bottom">
                        <AnimationOnScroll animateIn="animate__fadeIn" delay={(props.items.length + 1) * 100} offset={0}>
                            <Link to={(props.linkUrl ? props.linkUrl : "#")} className="link">
                                <span>
                                    {parse(props.linkText, htmlParseOptions)}
                                </span>
                                <Image desktop={require("../../assets/icons/arrow.svg").default}></Image>
                            </Link>
                        </AnimationOnScroll>
                    </div>
                }
            </div>  
        </section>
    )
}

export default ItemPreview;