import { Image } from "noyirmibir-react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import parse from 'html-react-parser';
import { htmlParseOptions } from "../../actions/utils";
import "./ticket-list.scss"
import { useStore } from "../../store/store";

type TicketListProps = {
    title?: string
    allPackagePrice?: string
    allPackageUrl?: string
    items: TicketListPropsItem[]
}

type TicketListPropsItem = {
    dateDay?: string
    dateMonth?: string
    name: string
    description: string
    program: string
    instructor: string
    place: string
    price: string
    buyUrl: string,
    isPast: boolean
}

const SliderList = (props: TicketListProps) => {
    const stringResources = useStore(state => state.stringResources);

    return (
        <section className="ticket-list">
            <div className="container">
                <div className="top">
                    {props.title &&
                        <AnimationOnScroll animateIn="animate__fadeIn" offset={0}>
                            <div className="title">
                                {parse(props.title, htmlParseOptions)}
                            </div>
                        </AnimationOnScroll>
                    }
                    {props.allPackagePrice &&   
                        <AnimationOnScroll animateIn="animate__fadeIn" offset={0}>
                            <a className="all-package-price" href={props.allPackageUrl} target={"_blank"}>
                                <span>
                                    {parse(stringResources["Web.TicketList.BuyAll"]??"", htmlParseOptions)}
                                </span>
                                <span>
                                    {parse(props.allPackagePrice, htmlParseOptions)}
                                </span>
                                <div className="icon">
                                    <Image desktop={require("../../assets/icons/buy.svg").default}></Image>
                                </div>
                            </a>
                        </AnimationOnScroll>
                    }
                </div>
                <div className="items">
                    {props.items.map((item: TicketListPropsItem, index: number) =>{
                        return (
                            <div className={"item" + (item.isPast ? " passive" : "")}>
                                <AnimationOnScroll animateIn="animate__fadeIn" offset={0}>
                                    <div className="top">
                                        {!!item.dateDay && !!item.dateMonth &&
                                            <div className="date">
                                                {item.dateDay}
                                                <br />
                                                <span>
                                                    {item.dateMonth}
                                                </span>    
                                            </div>
                                        }
                                        <div className="name">
                                            {item.name}    
                                        </div>
                                        <div className="program">
                                            {item.program}    
                                        </div>
                                        <div className="instructor">
                                            {item.instructor}    
                                        </div>
                                        <div className="place">
                                            {item.place}    
                                        </div>
                                        {item.price ?
                                            <>
                                                {item.isPast ? 
                                                    <div className="price">
                                                        <span>
                                                            {item.price}    
                                                        </span>
                                                        <div className="icon">
                                                            <Image desktop={require("../../assets/icons/buy.svg").default}></Image>
                                                        </div>
                                                    </div>
                                                    :
                                                    <a href={item.buyUrl} target={"_blank"} className="price">
                                                        <span>
                                                            {item.price}    
                                                        </span>
                                                        <div className="icon">
                                                            <Image desktop={require("../../assets/icons/buy.svg").default}></Image>
                                                        </div>
                                                    </a>
                                                }
                                            </>
                                            :
                                            <div className="price-placeholder">
                                            </div>
                                        }
                                    </div>
                                    {item.description &&
                                        <div className="bottom">
                                            <div className="description">
                                                {parse(item.description, htmlParseOptions)}
                                            </div>
                                        </div>
                                    }
                                </AnimationOnScroll>
                            </div>
                        )
                    })}
                </div>
            </div>  
        </section>
    )
}

export default SliderList;