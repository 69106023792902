import { Background, Image } from "noyirmibir-react";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper as SwiperType, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import parse from 'html-react-parser';
import { htmlParseOptions, isExternalLink } from "../../actions/utils";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "./promotion.scss"

type PromotionProps = {
    title?: string
    items: PromotionPropsItem[]
}

type PromotionPropsItem = {
    image: string
    subtitle?: string
    title?: string
    text?: string
    color: string
    linkUrl?: string
    linkText?: string
}

const Promotion = (props: PromotionProps) => {
    const [selectedItem, setSelectedItem] = useState<PromotionPropsItem|undefined>(props.items.length > 0 ? props.items[0] : undefined);
    
    const swiperRef = useRef<SwiperType|null>(null);

    useEffect(() => {
        setSelectedItem(props.items.length > 0 ? props.items[0] : undefined);
    }, [props.items]);

    return (
        <section className="promotion" style={{
            backgroundColor: selectedItem?.color
        }}>
            {selectedItem?.image &&
                <AnimationOnScroll animateIn="animate__fadeIn" offset={0}>
                    <Background classNames="background-image" desktop={selectedItem?.image??""} />
                </AnimationOnScroll>
            }
            <div className="container">
                <div className={"items" + (selectedItem?.image ? "" : " full")}>
                    <AnimationOnScroll animateIn="animate__fadeIn" delay={100} offset={0}>
                        <Swiper
                            modules={[Navigation]}
                            loop={true}
                            onSlideChange={(event) => setSelectedItem(props.items[event.realIndex])}
                            onBeforeInit={(swiper: any) => {
                                swiperRef.current = swiper;
                            }}
                        >
                            {props.items.map((item: PromotionPropsItem, index: number) => {
                                return (
                                    <SwiperSlide key={"promotion-" + index}>
                                        <div className="item">
                                            {props.title &&
                                                <div className="section-title">
                                                    {parse(props.title, htmlParseOptions)}
                                                </div>
                                            }
                                            {item.subtitle &&
                                                <div className="subtitle">
                                                    {parse(item.subtitle, htmlParseOptions)}
                                                </div>
                                            }
                                            {item.title &&
                                                <div className="title">
                                                    {parse(item.title, htmlParseOptions)}
                                                </div>
                                            }
                                            {item.text &&
                                                <div className="text">
                                                    {parse(item.text, htmlParseOptions)}
                                                </div>
                                            }
                                            {item.linkText &&
                                                <div className="bottom">
                                                    {item.linkUrl && isExternalLink(item.linkUrl) ?
                                                        <a href={item.linkUrl} className="link" target={"_blank"}>
                                                            <span>
                                                                {parse(item.linkText, htmlParseOptions)}
                                                            </span>
                                                            <Image desktop={require("../../assets/icons/arrow.svg").default}></Image>
                                                        </a>
                                                        :
                                                        <Link to={(item.linkUrl ? item.linkUrl : "#")} className="link">
                                                            <span>
                                                                {parse(item.linkText, htmlParseOptions)}
                                                            </span>
                                                            <Image desktop={require("../../assets/icons/arrow.svg").default}></Image>
                                                        </Link>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                        {props.items.length > 1 &&
                            <>
                                <div className="navigation left" onClick={() => swiperRef.current?.slidePrev()}>
                                    <Image desktop={require("../../assets/icons/arrow-left.svg").default}></Image>
                                </div>
                                <div className="navigation right" onClick={() => swiperRef.current?.slideNext()}>
                                    <Image desktop={require("../../assets/icons/arrow-right.svg").default}></Image>
                                </div>
                            </>
                        }
                    </AnimationOnScroll>
                </div>
            </div>  
        </section>
    )
}

export default Promotion;