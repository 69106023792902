import { Background } from "noyirmibir-react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "./half-image-text.scss"

type HalfImageTextProps = {
    backgroundColor?: string
    image: string
    subtitle?: string
    title?: string
    text?: string
    isDark?: boolean
    isImageLeft?: boolean
}

const HalfImageText = (props: HalfImageTextProps) => {

    return (
        <section className={"half-image-text" + (props.isDark ? " dark" : "") + (props.isImageLeft ? " image-left" : "")} style={{
            backgroundColor: (props.backgroundColor??"")
        }}>
            <div className="background-image-container">
                <Background classNames="background-image" desktop={props.image??""} />
            </div>
            <div className="container">
                <div className="content">
                    <AnimationOnScroll animateIn="animate__fadeIn" delay={props.isImageLeft ? 100 : 0} offset={0}>
                        {props.subtitle &&
                            <div className="subtitle">
                                {props.subtitle}
                            </div>
                        }
                        {props.title &&
                            <div className="title">
                                {props.title}
                            </div>
                        }
                        {props.text &&
                            <div className="text" dangerouslySetInnerHTML={{ __html: props.text }}></div>
                        }
                    </AnimationOnScroll>
                </div>
            </div>  
        </section>
    )
}

export default HalfImageText;