import { Image } from "noyirmibir-react";
import { Link } from "react-router-dom";
import { MenuModel, MenuModelItem } from "../../models/global";
import { Urls } from "../../navigation/main";
import parse from 'html-react-parser';
import { htmlParseOptions } from "../../actions/utils";
import { useStore } from "../../store/store";
import "./footer.scss"

const Footer = () => {
    const stringResources = useStore(state => state.stringResources);
    const menus = useStore(state => state.menus);

    const footerColumn1 = menus.find((item: MenuModel) => {
        return item.name == "Footer Column 1";
    });
    const footerColumn2 = menus.find((item: MenuModel) => {
        return item.name == "Footer Column 2";
    });
    const footerColumn3 = menus.find((item: MenuModel) => {
        return item.name == "Footer Column 3";
    });
    const footerColumn4 = menus.find((item: MenuModel) => {
        return item.name == "Footer Column 4";
    });

    return (
        <footer>
            <div className="container">
                <div className="logo">
                    <Image desktop={require("../../assets/icons/logo-circle.svg").default}></Image>
                </div>
                <div className="menu">
                    <div className="columns">
                        <div className="column">
                            <div className="title">
                                {parse(stringResources["Web.Footer.Column1Title"]??"", htmlParseOptions)}
                            </div>
                            {footerColumn1 && footerColumn1.items.map((item: MenuModelItem, index: number) => {
                                if(item.link.startsWith('https') || item.link.startsWith('http') || item.link.startsWith('mailto') || item.link.startsWith('tel')) {
                                    return (
                                        <a key={"footer-column1-" + index} href={item.link} target="_blank" className="item">
                                            {item.name}
                                        </a>
                                    )
                                }
                                else{
                                    return (
                                        <Link key={"footer-column1-" + index} className="item" to={item.link}>
                                            {item.name}
                                        </Link>
                                    )
                                }
                            })}
                        </div>
                        <div className="column">
                            <div className="title">
                                {parse(stringResources["Web.Footer.Column2Title"]??"", htmlParseOptions)}
                            </div>
                            {footerColumn2 && footerColumn2.items.map((item: MenuModelItem, index: number) => {
                                if(item.link.startsWith('https') || item.link.startsWith('http') || item.link.startsWith('mailto') || item.link.startsWith('tel')) {
                                    return (
                                        <a key={"footer-column2-" + index} href={item.link} target="_blank" className="item">
                                            {item.name}
                                        </a>
                                    )
                                }
                                else{
                                    return (
                                        <Link key={"footer-column2-" + index} className="item" to={item.link}>
                                            {item.name}
                                        </Link>
                                    )
                                }
                            })}
                        </div>
                        <div className="column">
                            <div className="title">
                                {parse(stringResources["Web.Footer.Column3Title"]??"", htmlParseOptions)}
                            </div>
                            {footerColumn3 && footerColumn3.items.map((item: MenuModelItem, index: number) => {
                                if(item.link.startsWith('https') || item.link.startsWith('http') || item.link.startsWith('mailto') || item.link.startsWith('tel')) {
                                    return (
                                        <a key={"footer-column3-" + index} href={item.link} target="_blank" className="item">
                                            {item.name}
                                        </a>
                                    )
                                }
                                else{
                                    return (
                                        <Link key={"footer-column3-" + index} className="item" to={item.link}>
                                            {item.name}
                                        </Link>
                                    )
                                }
                            })}
                        </div>
                        <div className="column">
                            <div className="title">
                                {parse(stringResources["Web.Footer.Column4Title"]??"", htmlParseOptions)}
                            </div>
                            {footerColumn4 && footerColumn4.items.map((item: MenuModelItem, index: number) => {
                                if(item.link.startsWith('https') || item.link.startsWith('http') || item.link.startsWith('mailto') || item.link.startsWith('tel')) {
                                    return (
                                        <a key={"footer-column4-" + index} href={item.link} target="_blank" className="item">
                                            {item.name}
                                        </a>
                                    )
                                }
                                else{
                                    return (
                                        <Link key={"footer-column4-" + index} className="item" to={item.link}>
                                            {item.name}
                                        </Link>
                                    )
                                }
                            })}
                        </div>
                        <div className="column">
                            <div className="title">
                                {parse(stringResources["Web.Footer.ColumnContactTitle"]??"", htmlParseOptions)}
                            </div>
                            <div className="item">
                                {parse(stringResources["Web.Footer.ColumnContactText"]??"", htmlParseOptions)}
                            </div>
                        </div>
                    </div>
                    <div className="bottom">
                        <Image desktop={require("../../assets/images/bank.png")}></Image>
                        <span>
                            {parse(stringResources["Web.Footer.BottomText"]??"", htmlParseOptions)}
                        </span>
                    </div>
                </div>
            </div>  
        </footer>
    )
}

export default Footer;