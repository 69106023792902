import { Popup, Image } from "noyirmibir-react"
import { ReactElement } from "react"
import "./alert.scss"

type AlertProps = {
    popup: Popup,
    children: ReactElement | ReactElement[]
    isOpen?: boolean,
    isSecondary?: boolean,
    btnConfirm?: {
        title: string,
        classNames?: string,
        callback?: () => void
    },
    btnCancel?: {
        title: string,
        classNames?: string,
        callback?: () => void
    }
}

const Alert = (props: AlertProps) => {
    return (
        <Popup classNames={"alert"} init={props.popup} isOpen={props.isOpen}>
            <div className="content">
                <div className="close" onClick={() =>{
                    props.popup.close()
                }}>
                    <Image desktop={require("../../assets/icons/close.svg").default}></Image>
                </div>
                <>
                    {props.children}
                </>
            </div>
        </Popup>
    )
}

export default Alert;