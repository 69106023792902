import { Image } from "noyirmibir-react";
import { Swiper as SwiperType, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import "./item-list.scss"
import 'swiper/css';
import 'swiper/css/navigation';
import { useRef } from "react";
import { Link } from "react-router-dom";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import parse from 'html-react-parser';
import { htmlParseOptions } from "../../actions/utils";

type ItemListProps = {
    title?: string
    items: ItemListPropsItem[]
}

type ItemListPropsItem = {
    image: string
    title?: string
    text?: string
    instructor: string
    instructorImage: string
    linkUrl?: string
    linkText: string
}

const ItemList = (props: ItemListProps) => {
    const swiperRef = useRef<SwiperType|null>(null);

    return (
        <section className="item-list">
            <div className="container">
                {props.title &&
                    <AnimationOnScroll animateIn="animate__fadeIn" offset={0}>
                        <div className="title">
                            {parse(props.title, htmlParseOptions)}
                        </div>
                    </AnimationOnScroll>
                }
                <div className="items">
                    <Swiper
                        modules={[Navigation]}
                        loop={true}
                        slidesPerView={1}
                        breakpoints={{
                            0: {
                                slidesPerView: 1.12,
                                centeredSlides: true
                            },
                            901: {
                              slidesPerView: 3
                            }
                        }}
                        observer={true}
                        observeParents={true}
                        onBeforeInit={(swiper: any) => {
                            swiperRef.current = swiper;
                        }}
                    >
                        {props.items.map((item: ItemListPropsItem, index: number) => {
                            return (
                                <SwiperSlide key={"item-list-" + index}>
                                    <AnimationOnScroll animateIn="animate__fadeIn" delay={index * 100} offset={0}>
                                        <Link className="item" to={item.linkUrl ? item.linkUrl : "#"}>
                                            <div className="content">
                                                <Image classNames="image" desktop={item.image} />
                                                {item.title &&
                                                    <div className="title">
                                                        {item.title}
                                                    </div>
                                                }
                                                {item.text &&
                                                    <div className="text">
                                                        {item.text}
                                                    </div>
                                                }
                                            </div>
                                            <div className="instructor">
                                                <div className="profile">
                                                    <Image desktop={item.instructorImage} />    
                                                </div>
                                                <div className="name">
                                                    {item.instructor}
                                                </div>
                                                <div className="link">
                                                    <span>
                                                        {parse(item.linkText, htmlParseOptions)}
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </AnimationOnScroll>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                    <div className="navigation left" onClick={() => swiperRef.current?.slidePrev()}>
                        <Image desktop={require("../../assets/icons/arrow-left.svg").default}></Image>
                    </div>
                    <div className="navigation right" onClick={() => swiperRef.current?.slideNext()}>
                        <Image desktop={require("../../assets/icons/arrow-right.svg").default}></Image>
                    </div>
                </div>
            </div>  
        </section>
    )
}

export default ItemList;