import { Image } from "noyirmibir-react";
import parse from 'html-react-parser';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { htmlParseOptions } from "../../actions/utils";
import "./banner.scss"
import { Link } from "react-router-dom";

type BannerProps = {
    image?: string
    video?: string
    icon?: string
    title?: string
    text?: string
}

const Banner = (props: BannerProps) => {
    return (
        <AnimationOnScroll animateIn="animate__fadeIn" offset={0}>
            <section className="banner">
                <div className="container">
                    <Link to={"https://www.instagram.com/ozgunlukokulu/"} className="image" style={{ backgroundImage: "url(" + props.image + ")" }}>
                        {props.video &&
                            <video loop muted playsInline autoPlay>
                                <source src={props.video} type={"video/mp4"} />
                            </video>
                        }
                        <div className="content">
                            {props.icon &&
                                <Image classNames="icon" desktop={props.icon}></Image>
                            }
                            {props.title &&
                                <div className="title">
                                    {parse(props.title, htmlParseOptions)}
                                </div>
                            }
                            {props.text &&
                                <div className="text">
                                    {parse(props.text, htmlParseOptions)}
                                </div>
                            }
                        </div>
                    </Link>
                </div>  
            </section>
        </AnimationOnScroll>
    )
}

export default Banner;