import { Image } from "noyirmibir-react";
import parse from 'html-react-parser';
import { htmlParseOptions } from "../../actions/utils";
import "./full-text.scss"

type MediaWithTextProps = {
    subtitle?: string
    title?: string
    text?: string,
    backgroundColor?: string
}

const MediaWithText = (props: MediaWithTextProps) => {
    return (
        <section className="full-text" style={{ backgroundColor: (props.backgroundColor??"") }}>
            <div className="container">
                {props.subtitle &&
                    <div className="subtitle">
                        {parse(props.subtitle, htmlParseOptions)}
                    </div>
                }
                {props.title &&
                    <div className="title">
                        {parse(props.title, htmlParseOptions)}
                    </div>
                }
                {props.text &&
                    <div className="text">
                        {parse(props.text, htmlParseOptions)}
                    </div>
                }
            </div>  
        </section>
    )
}

export default MediaWithText;