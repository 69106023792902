import { AnimationOnScroll } from 'react-animation-on-scroll';
import parse from 'html-react-parser';
import { htmlParseOptions } from '../../actions/utils';
import "./quotation.scss"

type QuotationProps = {
    text?: string,
    name?: string
}

const Quotation = (props: QuotationProps) => {
    return (
        <section className="quotation">
            <div className="container">
                <AnimationOnScroll animateIn="animate__fadeIn" offset={0}>
                    {props.text &&
                        <div className="text">
                            {parse(props.text, htmlParseOptions)}
                        </div>   
                    }
                    {props.name &&
                        <div className="name">
                            {parse(props.name, htmlParseOptions)}
                        </div>
                    }
                </AnimationOnScroll>
            </div>  
        </section>
    )
}

export default Quotation;