import { DOMNode, domToReact, Element as ParserElement } from 'html-react-parser';
import { Link } from 'react-router-dom';

export const htmlParseOptions = {
    replace: (domNode: DOMNode) => {
        if(domNode.type == "tag" && (domNode as ParserElement).name == "a")
        {
            const attributes = (domNode as ParserElement).attribs;
            const href = attributes.href;

            if(!isExternalLink)
            {
                const children = (domNode as ParserElement).children

                return <Link to={href}>{domToReact(children)}</Link>;
            }
        }
    }
}

export const formatNumber = (number: number) => {
    return (
      number
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    )
}

export const isExternalLink = (link: string) => {
    if(link.startsWith('https') || link.startsWith('http') || link.startsWith('mailto') || link.startsWith('tel')) {
        return true;
    }

    return false;
}