import { useLoading, useNoyirmibirStore } from "noyirmibir-react";
import axios from "axios";
import { useRef } from "react";
import { RequestHeaderModel } from "../models/global";
import { useStore } from "../store/store";

const appVersion = 1;
// const baseUrl = "http://localhost:57592/api/";
const baseUrl = "https://panel.ozgunlukokulu.com/api/";

export type AxiosModel = {
    url: string,
    method: "post" | "get" | "put" | "delete",
    data?: object,
    params?: object,
    successFunc?: Function,
    errorFunc?: Function,
    preventLoading?: Boolean,
    preventMessage?: Boolean
}

export const call = async (model: AxiosModel) => {
    const stringResources = useStore.getState().stringResources;
    const setMessage = useStore.getState().setMessage;
    
    if (!model.preventLoading) {
        const loadingCount = useNoyirmibirStore.getState().loading;
        useNoyirmibirStore.getState().setLoading({ count: loadingCount.count + 1 });
    }

    const headers: RequestHeaderModel = {
        languageId: 1,
        token: "",
        authorizationToken: ""
    };

    var isSuccess = false;

    await axios({
        method: model.method,
        headers: headers,
        data: model.data,
        params: model.params,
        url: baseUrl + model.url
    })
        .then(async(response: any) => {
            if (!model.preventLoading) {
                const loadingCount = useNoyirmibirStore.getState().loading;
                useNoyirmibirStore.getState().setLoading({ count: loadingCount.count - 1 })
            }

            var responseData = response.data;

            if (!model.preventMessage && responseData.messages && responseData.messages.length > 0) {
                setMessage({
                    text: responseData.messages.join(', '),
                    btnPrimaryText: stringResources["Web.Common.Ok"]
                });
            }
            
            if (responseData.success) {
                if (model.successFunc) {
                    await model.successFunc(responseData.data);
                }
            }
            else {
                if (model.errorFunc) {
                    await model.errorFunc(responseData.data);
                }
            }

            isSuccess = true;
        })
        .catch(async(error: any) => {            
            if (!model.preventLoading) {
                const loadingCount = useNoyirmibirStore.getState().loading;
                useNoyirmibirStore.getState().setLoading({ count: loadingCount.count - 1 })
            }
            
            if (!model.preventMessage && error.response && error.response.data && error.response.data.messages && error.response.data.messages.length > 0) {
                setMessage({
                    text: error.response.data.messages.join(', '),
                    btnPrimaryText: stringResources["Web.Common.Ok"]
                });
            }
            else if (!model.preventMessage) {
                setMessage({
                    text: stringResources["Web.Common.Error"],
                    btnPrimaryText: stringResources["Web.Common.Ok"]
                });
            }

            if (model.errorFunc) {
                await model.errorFunc();
            }
        });

    return isSuccess;
}