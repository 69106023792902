import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import MainRouter from './navigation/main';
import { Noyirmibir } from 'noyirmibir-react';
import ReactGA from "react-ga4";
import './assets/shared.scss';
import "animate.css/animate.min.css";

import moment from 'moment';
import 'moment/locale/tr';
moment.locale('tr')

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const MEASUREMENT_ID = "G-XZNND9R6X2";
ReactGA.initialize(MEASUREMENT_ID);
ReactGA.send("pageview");

root.render(
  <Noyirmibir>
      <MainRouter />
  </Noyirmibir>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
