import create from 'zustand'
import { AnouncementModel, HighlightModel, MenuModel, MessageModel, ProgramModel, StaticPageModel, StringResourceModel } from '../models/global';

type GlobalState = {
    message?: MessageModel,
    setMessage: (message: MessageModel) => void,
    stringResources: StringResourceModel,
    setStringResources: (resouces: StringResourceModel) => void,
    menus: MenuModel[],
    setMenus: (resouces: MenuModel[]) => void
    programs: ProgramModel[],
    setPrograms: (programs: ProgramModel[]) => void,
    highlights: HighlightModel[],
    setHighlights: (highlights: HighlightModel[]) => void,
    anouncements: AnouncementModel[],
    setAnouncements: (anouncements: AnouncementModel[]) => void,
    staticPages: StaticPageModel[],
    setStaticPages: (staticPages: StaticPageModel[]) => void
}

export const useStore = create<GlobalState>(set => ({
    setMessage: async (message: MessageModel) => set({ message: message }),
    stringResources: {},
    setStringResources: async (resources: StringResourceModel) => set({ stringResources: resources }),
    menus: [],
    setMenus: async (menus: MenuModel[]) => set({ menus: menus }),
    programs: [],
    setPrograms: async (programs: ProgramModel[]) => set({ programs: programs }),
    highlights : [],
    setHighlights: async (highlights: HighlightModel[]) => set({ highlights: highlights }),
    anouncements : [],
    setAnouncements: async (anouncements: AnouncementModel[]) => set({ anouncements: anouncements }),
    staticPages : [],
    setStaticPages: async (staticPages: StaticPageModel[]) => set({ staticPages: staticPages })
}));