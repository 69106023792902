import parse from 'html-react-parser';
import { htmlParseOptions } from "../../actions/utils";
import { Image } from "noyirmibir-react";
import "./media-with-text.scss"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom';

type MediaWithTextProps = {
    image?: string
    video?: string
    subtitle?: string
    title?: string
    text?: string,
    linkText?: string,
    linkUrl?: string,
    backgroundColor?: string
}

const MediaWithText = (props: MediaWithTextProps) => {
    return (
        <section className="media-with-text" style={{ backgroundColor: (props.backgroundColor??"") }}>
            <div className="container">
                <div className="image">
                    <AnimationOnScroll animateIn="animate__fadeIn" offset={0}>
                        {props.video ?
                            <video loop muted playsInline autoPlay>
                                <source src={props.video} type={"video/mp4"} />
                            </video>
                            :
                            <Image desktop={props.image??""}></Image>
                        }
                    </AnimationOnScroll>
                </div>
                <div className="content">
                        {props.subtitle &&
                            <AnimationOnScroll animateIn="animate__fadeIn" delay={100} offset={0}>
                                <div className="subtitle">
                                    {parse(props.subtitle, htmlParseOptions)}
                                </div>
                            </AnimationOnScroll>
                        }
                        {props.title &&
                            <AnimationOnScroll animateIn="animate__fadeIn" delay={100} offset={0}>
                                <div className="title">
                                    {parse(props.title, htmlParseOptions)}
                                </div>
                            </AnimationOnScroll>
                        }
                        {props.text &&
                            <AnimationOnScroll animateIn="animate__fadeIn" delay={100} offset={0}>
                                <div className="text">
                                    {parse(props.text, htmlParseOptions)}
                                </div>
                            </AnimationOnScroll>
                        }
                        {props.linkText &&
                            <AnimationOnScroll animateIn="animate__fadeIn" delay={100} offset={0}>
                                <div className='button'>
                                    <Link to={(props.linkUrl ? props.linkUrl : "#")} className="link">
                                        <span>
                                            {parse(props.linkText, htmlParseOptions)}
                                        </span>
                                        <Image desktop={require("../../assets/icons/arrow.svg").default}></Image>
                                    </Link>
                                </div>
                            </AnimationOnScroll>
                        }
                </div>
            </div>  
        </section>
    )
}

export default MediaWithText;