import { usePopup } from 'noyirmibir-react';
import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import Alert from '../components/alert';
import FreedomMapPage from '../pages/freedom-map';
import HomePage from '../pages/home';
import { Layout } from '../pages/layout';
import NotFound from '../pages/not-found';
import ProgramDetailPage from '../pages/program-detail';
import ProgramListPage from '../pages/program-list';
import StaticPage from '../pages/static';
import WhoIsYouPage from '../pages/who-is-you';
import { useStore } from '../store/store';
import { Image } from "noyirmibir-react";
import { getAnouncements, getHighlights, getMenus, getPrograms, getStaticPages, getStringResources } from '../actions/global-actions';

function MainRouter() {
    const message = useStore(state => state.message);
    const setMessage = useStore(state => state.setMessage);
    const [messageClosed, setMessageClosed] = useState<boolean>();

    const alert = usePopup();

    useEffect(() => {
        getStringResources();
        getMenus();
        getPrograms();
        getHighlights();
        getAnouncements();
        getStaticPages();

        window.addEventListener('load', animateLoading);
        if(document.readyState == "complete") {
            animateLoading(); 
        }

        if(!messageClosed) {
            setMessage({
                title: "",
                text: "",
                btnPrimaryCallback: () => {
                    setMessageClosed(true)
                },
            });
        } 

        return () => {
            window.removeEventListener('load', animateLoading);  
        }
    }, []);

    const animateLoading = () => {
        // const loadingLogo = document.querySelector('section.loading .logo');
        // loadingLogo?.classList.add('active');

        // setTimeout(() => {
        //     document.documentElement.classList.remove("loading");

        //     setTimeout(() =>{
        //         document.documentElement.classList.remove("lock");
        //     }, 1200);
        // }, 3000); 
    }

    useEffect(() => {
        if (message?.text) {
            alert.open();
        }
    }, [message]);
    
    return (
        <>
            <BrowserRouter>
                <Routes>
                    
                    {/* Private Pages */}
                    <Route element={<Layout></Layout>}>
                        <Route path={Urls.Home} element={<HomePage></HomePage>}></Route>
                        <Route path={Urls.FreedomMap} element={<FreedomMapPage></FreedomMapPage>}></Route>
                        <Route path={Urls.WhoIsYou} element={<WhoIsYouPage></WhoIsYouPage>}></Route>
                        <Route path={Urls.Static} element={<StaticPage></StaticPage>}></Route>
                        <Route path={Urls.ProgramList} element={<ProgramListPage></ProgramListPage>}></Route>
                        <Route path={Urls.ProgramDetail} element={<ProgramDetailPage></ProgramDetailPage>}></Route>
                    </Route>

                    {/* NotFound */}
                    <Route path="*" element={<NotFound></NotFound>}></Route>
                </Routes>
                {/* <section className="loading">
                    <div className="logo">
                        <svg width="1327" height="1136" viewBox="0 0 1327 1136" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_18_237)">
                                <path d="M728.288 676.229C842.492 624.423 894.054 555.1 886.562 405.855C891.815 370.427 902.146 356.146 930.281 338.001L1016.63 189.159L963.039 352.453L918.367 472.453C881.063 846.081 773.001 883.917 572.431 935.982M728.288 676.229C653.916 657.887 590.487 721.481 581.459 553.437C572.431 385.394 316.84 364.34 316.84 364.34C307.034 537.52 274.492 666.995 490.924 725.629L639.472 776.923M728.288 676.229C687.542 708.434 660.002 742.351 639.472 776.923M572.431 935.982C519.968 916.088 493.236 898.846 455.186 846.1M572.431 935.982C593.137 883.94 608.148 829.67 639.472 776.923" stroke="#A26955" strokeWidth="6"/>
                                <path d="M544.531 926.1C556.729 934.035 557.553 928.861 569.454 937.864C403.887 973.628 186.703 1052.22 168.299 1051.28C149.895 1050.33 130.21 1050.33 74.6224 1036.23C19.0343 1022.13 163.336 1038.43 163.336 1038.43L202.532 1027.81L211.091 1021.63C248.255 1020.42 510.436 935.935 544.531 926.1Z" stroke="#A26955" strokeWidth="6"/>
                                <path d="M486.177 884.217C494.133 897.372 494.171 889.391 498.404 894.568C357.336 927.751 245.433 963.708 108.797 981.628C73.1064 979.275 74.7 984.151 42.7461 959.511L103.782 964.969C118.523 963.398 230.479 949.28 243.536 945.393C288.221 932.081 421.76 901.538 486.177 884.217Z" stroke="#A26955" strokeWidth="6"/>
                                <path d="M572.46 934.089C760.328 884.171 863.349 799.982 906.14 415.512C915.528 392.051 930.18 392.574 930.18 355.436C930.18 318.299 1016.63 191.614 1016.63 191.614" stroke="#A26955" strokeWidth="6"/>
                                <path d="M571.76 933.94C571.76 1026.42 958.929 933.94 958.929 933.94C1049.04 903.512 844.674 741.744 733.991 758.1" stroke="#A26955" strokeWidth="6"/>
                                <path d="M370.544 678.57L0.94043 617.121C447.971 764.879 435.437 820.688 456.127 847.041" stroke="#A26955" strokeWidth="6"/>
                                <path d="M279.319 61.1585C265.688 174.018 321.64 349.159 321.64 349.159C435.765 503.103 510.602 582.201 723.22 670.57C723.22 670.57 314.634 -231.223 279.319 61.1585Z" stroke="#A26955" strokeWidth="6"/>
                                <path d="M915.29 879.995C860.765 821.774 871.545 751.807 845.749 679.609C819.954 607.412 954.246 624.601 1300.58 809.272C1436.67 881.833 915.29 879.995 915.29 879.995Z" stroke="#A26955" strokeWidth="6"/>
                                <path className="logo-icon" d="M735.5 432C775.541 432 808 399.541 808 359.5C808 319.459 775.541 287 735.5 287C695.459 287 663 319.459 663 359.5C663 399.541 695.459 432 735.5 432Z" fill="#A26955"/>
                                <path d="M804.996 359.041C804.996 399.445 773.721 431.982 735.402 431.982C697.082 431.982 665.807 399.445 665.807 359.041C665.807 318.637 697.082 286.1 735.402 286.1C773.721 286.1 804.996 318.637 804.996 359.041Z" stroke="#A26955" strokeWidth="6"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_18_237">
                                    <rect width="1327" height="1136" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="who-are-you">
                        <Image desktop={require("../assets/images/who-are-you.png")}></Image>
                    </div>
                </section> */}
            </BrowserRouter>
            <Alert 
                popup={alert} isSecondary={message?.showErrorIcon}
                btnConfirm={{
                    title: message?.btnPrimaryText??"",
                    callback:() => {
                        if(message?.btnPrimaryCallback) {
                            message?.btnPrimaryCallback();
                        }
                        alert.close();
                    },
                    classNames: (message?.showErrorIcon ? "red" : "")
                }}
                btnCancel={message?.btnSecondaryText ? {
                    title: message?.btnSecondaryText??"",
                    callback: () => {
                        if(message?.btnSecondaryCallback) {
                            message?.btnSecondaryCallback();
                        }
                        alert.close();
                    },
                    classNames: (message?.showErrorIcon ? "red" : "") 
                } : undefined}
            >
                <>
                    {message?.title &&
                        <div className="title">
                            {message?.title}
                        </div>
                    }
                    {message?.text &&
                        <div className="text" dangerouslySetInnerHTML={{ __html: message?.text }}></div>
                    }
                </>
            </Alert>
        </>
    )
}

export enum Urls {
    Home = "/",
    FreedomMap = "/ozgunluk-haritasi",
    WhoIsYou= "/sen-kimsin",
    ProgramList = "/programlar",
    ProgramDetail = "/program/:slug",
    Static = "/s/:slug"
}

export default MainRouter;