import moment from 'moment';
import { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { formatNumber } from '../../actions/utils';
import ItemList from "../../components/item-list";
import SliderList from '../../components/slider-list';
import TicketList from '../../components/ticket-list';
import { AnouncementModel, ProgramModel, ProgramModelCourse } from '../../models/global';
import { Urls } from '../../navigation/main';
import { useStore } from '../../store/store';
import "./program-list.scss"

const ProgramListPage = () => {
    const stringResources = useStore(state => state.stringResources);
    const programs = useStore(state => state.programs);
    const anouncements = useStore(state => state.anouncements);

    const [listPrograms, setListPrograms] = useState<ProgramModel[]>([]);
    const [listAnouncements, setListAnouncements] = useState<AnouncementModel[]>([]);

    useEffect(() => {
        document.body.classList.add('grey');

        return () => {
            document.body.classList.remove('grey');
        }
    }, []);

    useEffect(() => {
        const listProgramsData = programs.filter((item: ProgramModel) => {
            return item.showAtList;
        });
        setListPrograms(listProgramsData);
    }, [programs]);

    useEffect(() => {
        const listAnouncementsData = anouncements.filter((item: AnouncementModel) => {
            return item.showAtList;
        });
        setListAnouncements(listAnouncementsData);
    }, [anouncements]);

    const nextCourses = programs.flatMap((program) => {
        return program.courses.map((course) => {
            return { 
                programTitle: program.title,
                programInstructor: program.instructor,
                ...course
            }
        })
    }).filter((item) => { if(!item.isPast) { return item }}).sort((a, b) => {
        if(!a.date) {
            return 1;
        }
        else if(!b.date) {
            return -1
        }
        else if(a.date > b.date) {
            return 1;
        }
        else {
            return -1;
        }
    });

    return (
        <>
            <MetaTags>
                <title>Özgünlük Okulu | Programlar</title>
                <meta name="title" content="Özgünlük Okulu" />
                <meta name="description" content="Özgünlük Okulu, Kundalini Yoga ve Meditasyon öğretisini temel alarak ve pek çok farklı disiplini, spiritüel yaklaşımı bu kadim öğretiyle harmanlayarak hazırladığımız programlarla, seanslarla sıradanın mükemmelliğini deneyimlemen için açtığımız kollektif bir alan." />
                <meta property="og:title" content="Özgünlük Okulu" />
                <meta property="og:description" content="Özgünlük Okulu, Kundalini Yoga ve Meditasyon öğretisini temel alarak ve pek çok farklı disiplini, spiritüel yaklaşımı bu kadim öğretiyle harmanlayarak hazırladığımız programlarla, seanslarla sıradanın mükemmelliğini deneyimlemen için açtığımız kollektif bir alan." />
                <meta property="og:image" content="https://ozgunlukokulu.com/social-image.png" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Özgünlük Okulu" />
                <meta name="twitter:description" content="Özgünlük Okulu, Kundalini Yoga ve Meditasyon öğretisini temel alarak ve pek çok farklı disiplini, spiritüel yaklaşımı bu kadim öğretiyle harmanlayarak hazırladığımız programlarla, seanslarla sıradanın mükemmelliğini deneyimlemen için açtığımız kollektif bir alan." />
            </MetaTags>
            {listPrograms.length > 0 &&
                <ItemList
                    title={stringResources["Web.ProgramList.Title"]}
                    items={listPrograms.map((item: ProgramModel) => {
                        return {
                            image: item.image,
                            title: item.title,
                            text: item.shortDescription,
                            instructor: item.instructor,
                            instructorImage: item.instructorImage,
                            linkUrl: (item.showDetail ? Urls.ProgramDetail.replace(":slug", item.slug) : ""),
                            linkText: (item.showDetail ? (stringResources["Web.ProgramsSlider.Detail"] ?? "") : (stringResources["Web.ProgramsSlider.ComingSoon"] ?? ""))  
                        }
                    })}
                />
            }
            {listAnouncements.length > 0 &&
                <SliderList
                    backgroundColor='#FFF3DB'
                    title={stringResources["Web.ProgramList.AnouncementsTitle"]}
                    items={listAnouncements.map((item: AnouncementModel) => {
                        return {
                            image: item.image,
                            title: (!!item.title ? "<p>" + item.title + "</p>" : undefined),
                            text:  (!!item.text ? "<p>" + item.text + "</p>" : undefined),
                            linkUrl: item.linkUrl
                        }
                    })} 
                />
            }
            {nextCourses && nextCourses.length > 0 &&
                <TicketList
                    title={stringResources["Web.ProgramList.TicketsTitle"]}
                    items={nextCourses.map((course: any) => {
                        return {
                            dateDay: course.date ? moment(course.date).format('D') : undefined,
                            dateMonth: course.date ? moment(course.date).format('MMMM').substring(0, 3) : undefined,
                            name: course.title,
                            description: course.description,
                            program: course.programTitle,
                            instructor: !!course.instructor ? course.instructor : course.programInstructor,
                            place: course.location,
                            price: course.price,
                            buyUrl: course.purchaseLink,
                            isPast: course.isPast
                        }
                    })}
                />
            }
        </>
    )
}

export default ProgramListPage;