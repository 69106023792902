import MetaTags from 'react-meta-tags';
import MediaWithText from '../../components/media-with-text';
import { useStore } from '../../store/store';
import "./who-is-you.scss"

const WhoIsYouPage = () => {
    const stringResources = useStore(state => state.stringResources);

    return (
        <>
            <MetaTags>
                <title>Özgünlük Okulu | Sen Kimsin?</title>
                <meta name="title" content="Özgünlük Okulu" />
                <meta name="description" content="Özgünlük Okulu, Kundalini Yoga ve Meditasyon öğretisini temel alarak ve pek çok farklı disiplini, spiritüel yaklaşımı bu kadim öğretiyle harmanlayarak hazırladığımız programlarla, seanslarla sıradanın mükemmelliğini deneyimlemen için açtığımız kollektif bir alan." />
                <meta property="og:title" content="Özgünlük Okulu" />
                <meta property="og:description" content="Özgünlük Okulu, Kundalini Yoga ve Meditasyon öğretisini temel alarak ve pek çok farklı disiplini, spiritüel yaklaşımı bu kadim öğretiyle harmanlayarak hazırladığımız programlarla, seanslarla sıradanın mükemmelliğini deneyimlemen için açtığımız kollektif bir alan." />
                <meta property="og:image" content="https://ozgunlukokulu.com/social-image.png" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Özgünlük Okulu" />
                <meta name="twitter:description" content="Özgünlük Okulu, Kundalini Yoga ve Meditasyon öğretisini temel alarak ve pek çok farklı disiplini, spiritüel yaklaşımı bu kadim öğretiyle harmanlayarak hazırladığımız programlarla, seanslarla sıradanın mükemmelliğini deneyimlemen için açtığımız kollektif bir alan." />
            </MetaTags>
            <MediaWithText
                image={require("../../assets/images/start.jpg")}
                video={require("../../assets/images/video.mp4")}
                subtitle={stringResources["Web.WhoIsYou.Subtitle"]}
                title={stringResources["Web.WhoIsYou.Title"]}
                text={stringResources["Web.WhoIsYou.Text"]}
            />
        </>
    )
}

export default WhoIsYouPage;