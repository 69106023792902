import { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import Banner from "../../components/banner";
import MediaWithText from "../../components/media-with-text";
import ItemList from "../../components/item-list";
import ItemPreview from "../../components/item-preview";
import Promotion from "../../components/promotion";
import Quotation from "../../components/quotation";
import SliderList from "../../components/slider-list";
import "./home.scss"
import { Urls } from "../../navigation/main";
import { useStore } from "../../store/store";
import { AnouncementModel, HighlightModel, ProgramModel } from "../../models/global";

const HomePage = () => {
    const stringResources = useStore(state => state.stringResources);
    const programs = useStore(state => state.programs);
    const highlights = useStore(state => state.highlights);
    const anouncements = useStore(state => state.anouncements);

    const [homePrograms, setHomePrograms] = useState<ProgramModel[]>([]);
    const [homeAnouncements, setHomeAnouncements] = useState<AnouncementModel[]>([]);

    useEffect(() => {
        document.body.classList.add('no-border');

        return () => {
            document.body.classList.remove('no-border');
        }
    }, []);

    useEffect(() => {
        const homeProgramsData = programs.filter((item: ProgramModel) => {
            return item.showAtHome;
        });
        setHomePrograms(homeProgramsData);
    }, [programs]);

    useEffect(() => {
        const homeAnouncementsData = anouncements.filter((item: AnouncementModel) => {
            return item.showAtHome;
        });
        setHomeAnouncements(homeAnouncementsData);
    }, [anouncements]);

    return (
        <>
            <MetaTags>
                <title>Özgünlük Okulu</title>
                <meta name="title" content="Özgünlük Okulu" />
                <meta name="description" content="Özgünlük Okulu, Kundalini Yoga ve Meditasyon öğretisini temel alarak ve pek çok farklı disiplini, spiritüel yaklaşımı bu kadim öğretiyle harmanlayarak hazırladığımız programlarla, seanslarla sıradanın mükemmelliğini deneyimlemen için açtığımız kollektif bir alan." />
                <meta property="og:title" content="Özgünlük Okulu" />
                <meta property="og:description" content="Özgünlük Okulu, Kundalini Yoga ve Meditasyon öğretisini temel alarak ve pek çok farklı disiplini, spiritüel yaklaşımı bu kadim öğretiyle harmanlayarak hazırladığımız programlarla, seanslarla sıradanın mükemmelliğini deneyimlemen için açtığımız kollektif bir alan." />
                <meta property="og:image" content="https://ozgunlukokulu.com/social-image.png" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Özgünlük Okulu" />
                <meta name="twitter:description" content="Özgünlük Okulu, Kundalini Yoga ve Meditasyon öğretisini temel alarak ve pek çok farklı disiplini, spiritüel yaklaşımı bu kadim öğretiyle harmanlayarak hazırladığımız programlarla, seanslarla sıradanın mükemmelliğini deneyimlemen için açtığımız kollektif bir alan." />
            </MetaTags>
            <Banner
                video={require("../../assets/images/banner.mp4")}
                icon={require("../../assets/images/banner-message.svg").default}
                title={stringResources["Web.Banner.Title"]}
                text={stringResources["Web.Banner.Text"]}
            />
            {homePrograms.length > 0 &&
                <ItemList
                    items={homePrograms.map((item: ProgramModel) => {
                        return {
                            image: item.image,
                            title: item.title,
                            text: item.shortDescription,
                            instructor: item.instructor,
                            instructorImage: item.instructorImage,
                            linkUrl: (item.showDetail ? Urls.ProgramDetail.replace(":slug", item.slug) : ""),
                            linkText: (item.showDetail ? (stringResources["Web.ProgramsSlider.Detail"] ?? "") : (stringResources["Web.ProgramsSlider.ComingSoon"] ?? ""))  
                        }
                    })}
                />
            }
            <MediaWithText
                image={require("../../assets/images/flow.jpg")}
                // video={require("../../assets/images/flow.mp4")}
                subtitle={stringResources["Web.Start.Subtitle"]}
                title={stringResources["Web.Start.Title"]}
                text={stringResources["Web.Start.Text"]}
                linkText={stringResources["Web.Start.LinkText"]}
                linkUrl={Urls.FreedomMap}
            />
            <ItemPreview
                title={stringResources["Web.Items.Title"]}
                text={stringResources["Web.Items.Text"]}
                items={[
                    {
                        image: require("../../assets/images/program-1-preview.jpg"),
                        title: stringResources["Web.Items.FirstTitle"]
                    },
                    {
                        image: require("../../assets/images/program-2-preview.jpg"),
                        title: stringResources["Web.Items.SecondTitle"]
                    },
                    {
                        image: require("../../assets/images/program-3-preview.jpg"),
                        title: stringResources["Web.Items.ThirdTitle"]
                    },
                    {
                        image: require("../../assets/images/program-4-preview.jpg"),
                        title: stringResources["Web.Items.FourthTitle"]
                    }
                ]}
                linkText={stringResources["Web.Items.Link"]}
                linkUrl={Urls.ProgramList}
            />
            <Quotation
                text={stringResources["Web.Quotation.Text"]}
                name={stringResources["Web.Quotation.Person"]}
            />
            {highlights.length > 0 &&
                <Promotion
                    title={stringResources["Web.Highlight.Title"]}
                    items={highlights.map((item: HighlightModel) => {
                        return {
                            subtitle: (!!item.subtitle ? "<p>" + item.subtitle + "</p>" : undefined),
                            title: (!!item.title ? "<p>" + item.title + "</p>" : undefined),
                            text: item.text,
                            color: item.backgroundColor,
                            linkUrl: item.linkUrl,
                            linkText: (!!item.linkText ? "<p>" + item.linkText + "</p>" : undefined),
                            image: item.image
                        }
                    })}
                />
            }
            <MediaWithText
                image={require("../../assets/images/working.jpg")}
                subtitle={stringResources["Web.Awareness.Subtitle"]}
                title={stringResources["Web.Awareness.Title"]}
                text={stringResources["Web.Awareness.Text"]}
                linkText={stringResources["Web.Awareness.LinkText"]}
                linkUrl={Urls.FreedomMap}
            />
            {homeAnouncements.length > 0 &&
                <SliderList
                    title={stringResources["Web.Interested.Title"]}
                    linkText={stringResources["Web.Interested.Link"]}
                    linkUrl={"https://blog.ozgunlukokulu.com"}
                    items={homeAnouncements.map((item: AnouncementModel) => {
                            return {
                                image: item.image,
                                title: (!!item.title ? "<p>" + item.title + "</p>" : undefined),
                                text:  (!!item.text ? "<p>" + item.text + "</p>" : undefined),
                                linkUrl: item.linkUrl
                            }
                    })} 
                />
            }
        </>
    )
}

export default HomePage;