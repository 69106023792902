import { useEffect } from 'react';
import { Outlet, Navigate, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import Footer from '../../components/footer';
import Header from '../../components/header';

export const Layout = () => {
    const navigate = useNavigate();


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);

    return <>
        <div className='main'>
            <Header></Header>
            <Outlet />
            <Footer />
        </div>
    </>
}