import { Image } from "noyirmibir-react";
import MetaTags from 'react-meta-tags';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Urls } from '../../navigation/main';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import parse from 'html-react-parser';
import { htmlParseOptions } from "../../actions/utils";
import "./static.scss"
import { useStore } from "../../store/store";
import { useEffect, useState } from "react";
import { StaticPageModel } from "../../models/global";

const StaticPage = () => {
    let { slug } = useParams();
    
    const stringResources = useStore(state => state.stringResources);
    const staticPages = useStore(state => state.staticPages);

    const [staticPage, setStaticPage] = useState<StaticPageModel>();

    useEffect(() => {
        const staticPageData = staticPages.find((item: StaticPageModel) => {
            return item.slug == slug;
        });

        if(staticPageData)
        {
            setStaticPage(staticPageData);
        }
    }, [staticPages]);

    useEffect(() => {
        const staticPageData = staticPages.find((item: StaticPageModel) => {
            return item.slug == slug;
        });

        if(staticPageData)
        {
            setStaticPage(staticPageData);
        }
    }, [slug]);

    return (
        <>
            <MetaTags>
                <title>Özgünlük Okulu | Bilgiler</title>
                <meta name="title" content="Özgünlük Okulu" />
                <meta name="description" content="Özgünlük Okulu, Kundalini Yoga ve Meditasyon öğretisini temel alarak ve pek çok farklı disiplini, spiritüel yaklaşımı bu kadim öğretiyle harmanlayarak hazırladığımız programlarla, seanslarla sıradanın mükemmelliğini deneyimlemen için açtığımız kollektif bir alan." />
                <meta property="og:title" content="Özgünlük Okulu" />
                <meta property="og:description" content="Özgünlük Okulu, Kundalini Yoga ve Meditasyon öğretisini temel alarak ve pek çok farklı disiplini, spiritüel yaklaşımı bu kadim öğretiyle harmanlayarak hazırladığımız programlarla, seanslarla sıradanın mükemmelliğini deneyimlemen için açtığımız kollektif bir alan." />
                <meta property="og:image" content="https://ozgunlukokulu.com/social-image.png" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Özgünlük Okulu" />
                <meta name="twitter:description" content="Özgünlük Okulu, Kundalini Yoga ve Meditasyon öğretisini temel alarak ve pek çok farklı disiplini, spiritüel yaklaşımı bu kadim öğretiyle harmanlayarak hazırladığımız programlarla, seanslarla sıradanın mükemmelliğini deneyimlemen için açtığımız kollektif bir alan." />
            </MetaTags>
            <section className="static">
                <div className="container">
                    <div className="menu">
                        <div className='items'>
                            {staticPages.map((item) => {
                                return (
                                    <Link className={"item"} to={Urls.Static.replace(":slug", item.slug)}>
                                        <AnimationOnScroll animateIn="animate__fadeIn" offset={0}>
                                            <span>
                                                {item.menuTitle}
                                            </span>
                                            {staticPage?.slug == item.slug &&
                                                <Image desktop={require("../../assets/icons/arrow-right.svg").default} />
                                            }
                                        </AnimationOnScroll>
                                    </Link>     
                                )
                            })}
                        </div>
                    </div>
                    <div className='content'>
                        <AnimationOnScroll animateIn="animate__fadeIn" offset={0}>
                            {staticPage?.title &&
                                <div className='title'>
                                    {staticPage.title}
                                </div>
                            }
                            {staticPage?.image && !staticPage?.video &&
                                <div className='image'>
                                    <Image desktop={staticPage.image} />
                                </div>
                            }
                            {staticPage?.video &&
                                <div className='video'>
                                    <video poster={staticPage?.image} controls>
                                        <source src={staticPage.video} type={"video/mp4"} />
                                    </video>
                                </div>
                            }
                            {staticPage?.text &&
                                <div className='text'>
                                    {parse(staticPage.text, htmlParseOptions)}
                                </div>
                            }
                        </AnimationOnScroll>
                    </div>
                </div>
            </section>
        </>
    )
}

export default StaticPage;